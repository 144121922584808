// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$logistique-pn-front-primary: mat.define-palette(mat.$indigo-palette);
$logistique-pn-front-accent: mat.define-palette(
    mat.$pink-palette,
    A200,
    A100,
    A400
);

// The warn palette is optional (defaults to red).
$logistique-pn-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$logistique-pn-front-theme: mat.define-light-theme(
    (
      color: (
        primary: $logistique-pn-front-primary,
        accent: $logistique-pn-front-accent,
        warn: $logistique-pn-front-warn,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($logistique-pn-front-theme);

//Height by default is 56px and 1 densities = 4px

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.dense-plus-2 {
  @include mat.all-component-densities(2);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: Arial, serif;
  font-weight: 400;
  line-height: 16.1px;
  letter-spacing: 0.5px;

  //overide class angular material , the width is fix for input text and we don't want
  .mat-mdc-form-field-infix {
    flex: 1;
    position: relative;
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
  }

  button:hover {
    filter: brightness(95%);
  }
}

/*custom colors*/
:root {
  --white: #ffffff;
  --white-default-background: #fafafa;
  --white-action-background: #f5f5f5;
  --red-default: #c40000;
  --red-error: #e31e32;
  --red-error-background: #e31e3240;
  --blue-default: #051039;
  --blue-focus: #0061ce1a;
  --grey-hover: #f0f0f0;
  --grey-pipe: #0000002e;
  --active-button-menu: #ffffff40;
  --border-default: #b0bec5;
  --border-dark: #00000061;
  --filter-title: #00000099;
  --status-color: #000000de;
  --yellow-hop: #ffb500;
  --light-grey: #0000000f;
  --light-grey-without-transparency: #f0f0f0;
  --green-hop: #7fba00;
  --green-success-text: #03821e;
  --green-success-background: #c0dfc6;
  --black-058: #00000094;
  --blue-grey: #607d8b;
}

.mdc-checkbox
.mdc-checkbox__native-control:enabled:checked
~ .mdc-checkbox__background,
.mdc-checkbox
.mdc-checkbox__native-control:enabled:indeterminate
~ .mdc-checkbox__background,
.mdc-checkbox
.mdc-checkbox__native-control[data-indeterminate='true']:enabled
~ .mdc-checkbox__background {
  border-color: var(--red-default) !important;
  background-color: var(--red-default) !important;
}

/* fonts */
.subtitle-2 {
  //styleName: v-text/subtitle-2;
  font-family: Arial, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.4px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

h4 {
  //styleName: v-text/h4;
  font-family: Arial, serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 36.8px;
  letter-spacing: 0.25px;
  text-align: center;

  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.line-through {
  text-decoration: line-through;
}

.grey-text {
  color: var(--filter-title);
}

.blue-grey-text {
  color: var(--blue-grey);
}

.uppercase {
  text-transform: uppercase;
}

/* global css */
.vertical-line {
  width: 1px;
  height: 100%;
  background-color: var(--grey-pipe);
  margin: 0 10px;
  align-self: center;
}

/* css components */
.content {
  display: flex;
}

.panel {
  display: flex;
  flex: 0 1 50%;
  max-width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @extend .pd-sm;
}

.card-light {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-around;
  padding: 24px 56px 24px 56px;
  gap: 0;
  border-radius: 8px;
  border: 1px solid var(--border-dark);
  height: fit-content;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  box-shadow: 0 1px 5px 0 #0000001f;
  gap: 42px;
  align-items: center;
  background: white;
  height: fit-content;
}

.error-container {
  display: block;
  background: var(--red-error-background);
  color: var(--red-default);
  flex: 1;
  padding: 14px 16px 14px 16px;
  border-radius: 4px;
  margin: 10px 0;
}

.success-container {
  display: block;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20%;
  background: var(--green-success-background);
  color: var(--green-success-text);
  padding: 14px 16px;
  border-radius: 4px;
  z-index: 1000;
}

.header-container {
  height: 104px;
  width: 100%;
  display: flex;
  background: white;
  justify-content: space-between;
  border-bottom: 1px solid rgba(176, 190, 197, 0.5);

  .title {
    color: var(--red-default);
    font-size: 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    line-height: 18px;
  }
}

.breadcrumbs-container {
  padding: 35px 10px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;

  .status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
  }
}

.filter-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 16px 20px 24px 0;
  width: 100%;

  .filter-content {
    display: flex;
    gap: 18px;
    width: 100%;
  }
}

.actions-container {
  display: flex;
  gap: 18px;
  justify-content: flex-end;
  width: fit-content !important;
  padding: 36px 20px 24px 0px;
}

.input-field,
.input-field-date,
.input-field-range-date,
.input-field-large,
input-field-bold {
  display: flex;
  flex-direction: column;
  // Forms with angular material add margin bottom of 22px we must add 22px to inline element with others
  font-size: 14px;
  top: 22px;
  gap: 4px;
  width: 105px;

  mat-form-field {
    font-size: 14px;

    .prefix-text {
      padding-left: 10px;
    }
  }

  mat-checkbox {
    width: max-content;
  }

  .input-field-label {
    color: var(--filter-title);
  }
}

.input-field-large {
  width: 130px;
}

.input-field-date {
  width: 140px;
}

.input-field-range-date {
  width: 228px;
}

.input-field-bold {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      background: rgba(0, 0, 0, 0.06);
    }

    mat-select,
    .mat-mdc-text-field-wrapper {
      font-weight: 700;
    }
  }
}

/** buttons **/

.button-text-black,
.button-text-red {
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
}

.button-text-black {
  color: var(--black-058);
  font-weight: bold;
}

.button-text-red {
  color: var(--red-default);
}

.button-red {
  height: 44px;
  width: 153px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid var(--red-default);
  font-weight: 700;
  line-height: 16.1px;
  letter-spacing: 1.25px;
  background: var(--red-default);
  color: white;
}

.button-red:disabled {
  background: white;
  color: var(--red-default);
}

.button-cancel {
  height: 52px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  line-height: 16.1px;
  letter-spacing: 1.25px;
}

.button-save {
  height: 52px;
  background: var(--red-default);
  border: none;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-weight: 700;
  line-height: 16.1px;
  letter-spacing: 1.25px;
}

.button-save:disabled {
  background-color: #f5f5f5;
}

/** table **/

.table-container {
  flex-grow: 1;
  overflow: auto;
  background-color: var(--white);
  border-bottom: 1px solid transparent;
  border-radius: 8px;
}

.table-sticky {
  height: calc(100vh - 173px);
  overflow: auto;
}

@media (max-width: 1580px) {
  .table-sticky {
    height: calc(100vh - 187px);
  }
}

.action-table,
.title-table {
  flex: 1;
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #757575;
  font-weight: 700;
}

.title-table-dark {
  @extend .title-table;
  background: var(--light-grey-without-transparency) !important;
  color: var(--blue-default) !important;
}

.table-cell-number {
  text-align: right !important;
}

.table-cell-fit {
  height: 100%;
  padding: 0 16px 0 16px; // force cell to 100%
  margin: 0 -16px 0 -16px; // force cell to 100%
}

.table-cell-editable {
  @extend .table-cell-fit;
  display: flex;
  align-items: center;
  min-height: 20px;
  color: var(--blue-default);
  font-size: 14px;
  letter-spacing: 0.5px;
}

.table-cell-editing {
  @extend .table-cell-fit;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 17px;
}

.max-height-two-lines {
  overflow-y: auto;
  max-height: 48px;
}

.table-cell-yellow {
  @extend .table-cell-fit;
  background-color: var(--yellow-hop) !important;
}

.table-cell-green {
  @extend .table-cell-fit;
  background-color: var(--green-hop) !important;
}

.table-cell-dark {
  @extend .table-cell-fit;
  background: var(--light-grey) !important;
}

mat-paginator {
  border-top: 1px rgba(0, 0, 0, 0.12) inset;
  background-color: unset;
}

/** forms **/

.form-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background-color: var(--white);
  padding: 24px;
  gap: 36px;
}

.form-title {
  @extend .subtitle-2;
  flex-grow: 1;
  color: var(--red-default);
}

.required::after {
  content: '*';
  font-size: 1.2em;
}

/** utils **/

.no-wrap {
  text-wrap: nowrap;
}

// flex
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.gap-xs {
  display: flex;
  gap: 8px;
}

.gap-sm {
  display: flex;
  gap: 16px;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.fit-content {
  width: fit-content !important;
}

//font-weight
.font-bold {
  font-weight: bold;
}

// padding
.pd-sm {
  padding: 24px;
}

.pt-sm {
  padding-top: 24px;
}

.pt-md {
  padding-top: 48px;
}

// margin
.mt-xs {
  margin-top: 12px;
}

.mb-xs {
  margin-bottom: 12px;
}

.ml-xs {
  margin-left: 12px;
}

.mg-xs {
  margin: 12px;
}

.mg-sm {
  margin: 24px;
}

.mt-sm {
  margin-top: 24px;
}

.mb-sm {
  margin-bottom: 24px;
}

.ml-sm {
  margin-left: 24px;
}

.mb-md {
  margin-bottom: 48px;
}

// percents
.pc5 {
  width: 5%;
}

.pc10 {
  width: 10%;
}

.pc15 {
  width: 15%;
}

.pc20 {
  width: 20%;
}

.pc25 {
  width: 25%;
}

.pc30 {
  width: 30%;
}

.pc35 {
  width: 35%;
}

.pc40 {
  width: 40%;
}

.pc45 {
  width: 45%;
}

.pc50 {
  width: 50%;
}

.pc55 {
  width: 55%;
}

.pc60 {
  width: 60%;
}

.pc65 {
  width: 65%;
}

.pc70 {
  width: 70%;
}

.pc75 {
  width: 75%;
}

.pc80 {
  width: 80%;
}

.pc85 {
  width: 85%;
}

.pc90 {
  width: 90%;
}

.pc95 {
  width: 95%;
}

.pc100 {
  width: 100%;
}

// scroll
.scroll-y {
  overflow-y: auto;
}

// visible
.visible {
  visibility: visible !important;
}

//svg

//remove extract space below svg
svg {
  display: block;
}

// responsive

@media (max-width: 1365px) and (max-height: 1079px) {
  .responsive-sm {
    display: none !important;
  }
}
